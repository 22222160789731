import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Button,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { resetUserPassword } from "store/actions"
// import images
import logodark from "../../assets/images/logo-dark.png"
import CarouselPage from "./CarouselPage"

import { useDispatch, useSelector } from "react-redux"

const ResetPassword = () => {
  document.title = "Reset Password | Dragon - Animal care center"

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isSubmitDisable, setIsSubmitDisable] = useState(false)
  const [inputChange, setInputChange] = useState(false)

  const { resetPasswordMsg, resetPasswordLoading, resetPasswordErr } =
    useSelector(state => state.forgetPassword)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      confirmationCode: "",
      newPassword: "",
      repassword: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your username"),
      confirmationCode: Yup.string().required("Please Enter verfication Code"),
      newPassword: Yup.string()
        .required("This value is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
      repassword: Yup.string().when("newPassword", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Both password need to be the same"
        ),
      }),
    }),
    onSubmit: values => {
      console.log(values)
      setInputChange(false)
      dispatch(resetUserPassword(values))
    },
  })

  useEffect(() => {
    resetPasswordLoading ? setIsSubmitDisable(true) : setIsSubmitDisable(false)
  }, [resetPasswordLoading])

  useEffect(() => {
    setInputChange(true)
  }, [validation.values])

  useEffect(() => {
    if (resetPasswordMsg !== null) {
      const timer = setTimeout(() => {
        navigate("/login")
      }, 800)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [resetPasswordMsg])

  

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-3 mb-md-4">
                      <img
                        src={logodark}
                        alt=""
                        height="60"
                        className="auth-logo-dark d-block m-auto"
                      />
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-gold">Reset Password</h5>
                        <p className="text-muted">
                          Reset password with Dragon.
                        </p>
                      </div>

                      <div className="mt-4">
                        {resetPasswordErr !== null && !inputChange && (
                          <div
                            className="alert alert-danger text-center mb-4"
                            role="alert"
                          >
                            {resetPasswordErr}
                          </div>
                        )}

                        {resetPasswordMsg !== null && (
                          <div
                            className="alert alert-success text-center mb-4"
                            role="alert"
                          >
                            Enter your Email and instructions will be sent to
                            you!
                          </div>
                        )}

                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label mb-0 text-muted">
                              username
                            </Label>
                            <Input
                              name="username"
                              className="form-control"
                              placeholder="Enter username"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username &&
                                validation.errors.username
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.username &&
                            validation.errors.username ? (
                              <FormFeedback type="invalid">
                                {validation.errors.username}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label mb-0 text-muted">
                              verification Code
                            </Label>
                            <Input
                              name="confirmationCode"
                              className="form-control"
                              placeholder="Enter verfication Code"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirmationCode || ""}
                              invalid={
                                validation.touched.confirmationCode &&
                                validation.errors.confirmationCode
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.confirmationCode &&
                            validation.errors.confirmationCode ? (
                              <FormFeedback type="invalid">
                                {validation.errors.confirmationCode}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label mb-0 text-muted">
                              Password
                            </Label>
                            <Input
                              name="newPassword"
                              className="form-control"
                              placeholder="Enter password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.newPassword || ""}
                              invalid={
                                validation.touched.newPassword &&
                                validation.errors.newPassword
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.newPassword &&
                            validation.errors.newPassword ? (
                              <FormFeedback type="invalid">
                                {validation.errors.newPassword}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label mb-0 text-muted">
                              Re-type Password
                            </Label>
                            <Input
                              name="repassword"
                              className="form-control"
                              placeholder="Re-type Password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.repassword || ""}
                              invalid={
                                validation.touched.repassword &&
                                validation.errors.repassword
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.repassword &&
                            validation.errors.repassword ? (
                              <FormFeedback type="invalid">
                                {validation.errors.repassword}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mt-3 text-end">
                            <Button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              disabled={isSubmitDisable}
                            >
                              {isSubmitDisable && (
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 color-white"></i>
                              )}
                              Reset
                            </Button>
                          </div>
                        </Form>
                        <div className="mt-3 text-center">
                          <p>
                            Remember It ?{" "}
                            <Link
                              to="/login"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Sign In here{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3 mt-md-4 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Dragon Animal Care Center{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResetPassword
