import React, { useState, useEffect } from "react"
import {
  Col,
  Container,
  Row
} from "reactstrap"
// import images
import logodark from "../../assets/images/logo-dark.png"
import CarouselPage from "./CarouselPage"
import { Link } from "react-router-dom"

const EmailVerfication = () => {
  //meta title
  document.title = "Email Verification | Dragon - Animal care center"

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col cl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <a href="/" className="d-block auth-logo">
                      <img
                          src={logodark}
                          alt=""
                          height="100"
                          className="auth-logo-dark d-block m-auto"
                        />
                      </a>
                    </div>
                    <div className="my-auto">
                      <div className="text-center">
                        <div className="avatar-md mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            <i className="bx bxs-envelope h1 mb-0 text-danger"></i>
                          </div>
                        </div>
                        <div className="p-2 mt-4">
                          <h4>Verify your email</h4>
                          <p>
                            We have sent you verification code{" "}
                            , Please check your email.
                          </p>
                          <div className="mt-4">
                            <Link to="/reset-password" className="btn btn-success w-md">
                              Reset Password
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Dragon Animal Care Center{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EmailVerfication
