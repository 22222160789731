import { post as postNonAuth, get as getNonAuth } from "./axios_api"

//////// Buffalo Login ////////////
export const postLogin = async data => {
    console.log(data)
    const res = await postNonAuth("Login", data)
    console.log(res)
    return res
//   try {
//     console.log(data)
//     return await postNonAuth("Login", data).then(response => response)
//   } catch ({ response }) {
//     return response
//   }
}

export const getLogout = async data => {
    const res = await getNonAuth(`/logout?client_id=${data}&logout_uri=https://stage.smartqr.app/cognito/signout`)
    return res
}