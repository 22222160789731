import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOGIN_FAIL,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  loginData: "",
  loginErr: "",
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
        loginData: "",
        loginErr: "",
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        // isUserLogout: false,
        loginData: action.payload,
        loginErr: "",
      }
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        loginErr: action.payload,
      }
    case LOGOUT_USER:
      return { ...state }

    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        loginData: "",
      }

    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default login
