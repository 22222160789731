import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from "./actionTypes"

export const userForgetPassword = (user, history) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, history },
  }
}

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  }
}

export const resetUserPassword = (user, history) => {
  return {
    type: RESET_PASSWORD,
    payload: { user, history }
  }
}

export const resetUserPasswordSuccess = data => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: data
  }
}

export const resetUserPasswordFail = err => {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: err
  }
}


