import { GET_SSO_APP, GET_SSO_APP_FAIL, GET_SSO_APP_SUCCESS } from "./actionTypes"

export const getSsoApp = (user, history) => {
  return {
    type: GET_SSO_APP,
    payload: { user, history },
  }
}

export const getSsoAppSuccess = data => {
    return {
      type: GET_SSO_APP_SUCCESS,
      payload: data,
    }
  }
  export const getSsoAppFail = err => {
    return {
      type: GET_SSO_APP_FAIL,
      payload: err,
    }
  }
  
