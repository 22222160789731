import {
  GET_SSO_APP,
  GET_SSO_APP_SUCCESS,
  GET_SSO_APP_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  getSsoAppLoading: false,
  getSsoAppData: "",
  getSsoAppErr: "",
}

const ssoApplications = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SSO_APP:
      return {
        ...state,
        getSsoAppLoading: true,
        getSsoAppData: "",
      }

      case GET_SSO_APP_SUCCESS:
      return {
        ...state,
        getSsoAppLoading: false,
        getSsoAppData: action.payload,
        getSsoAppErr: ""
      }

      case GET_SSO_APP_FAIL:
      return {
        ...state,
        getSsoAppLoading: false,
        getSsoAppData: "",
        getSsoAppErr: action.payload
      }

    default:
      return state
  }
}

export default ssoApplications
