import React from "react";
import { Navigate } from "react-router-dom";

import Login2 from "pages/AuthenticationInner/Login2";

import ForgetPassword2 from "pages/AuthenticationInner/ForgetPassword2";

import Logout from "pages/AuthenticationInner/logout";
import SSOAplication from "pages/SSOAplications";
import EmailVerfication from "pages/AuthenticationInner/AuthEmailVerification";

import ResetPassword from "pages/AuthenticationInner/ResetPassword";

const authProtectedRoutes = [
  { path: "/sso-applications", component: <SSOAplication />},
 ]

const publicRoutes = [
  { path: "/login", component: <Login2 /> },
  { path: "/forget-password", component: <ForgetPassword2 /> },
  { path: "/email-verification", component: <EmailVerfication /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/logout", component: <Logout /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/login" />,
  },
];

export {  publicRoutes, authProtectedRoutes };
