import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from "./actionTypes"

const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
  loading: false,

  resetPasswordMsg: null,
  resetPasswordErr: null,
  resetPasswordLoading: false,
}

const forgetPassword = (state = initialState, action) => {
  console.log(action)
  switch (action.type) {
    case FORGET_PASSWORD:
      return {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null,
        loading: true,
      }
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetSuccessMsg: action.payload,
        loading: false,
      }
    case FORGET_PASSWORD_ERROR:
      return {
        ...state,
        forgetSuccessMsg: null,
        forgetError: action.payload,
        loading: false,
      }

    case RESET_PASSWORD:
      return {
        ...state,
        resetPasswordMsg: null,
        resetPasswordLoading: true,
        resetPasswordErr: null,
      }

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordMsg: action.payload,
        resetPasswordLoading: false,
        resetPasswordErr: null,
      }

    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordMsg: null,
        resetPasswordLoading: false,
        resetPasswordErr: action.payload,
      }
      
    default:
      return state
  }
}

export default forgetPassword
