import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD, RESET_PASSWORD } from "./actionTypes"
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  resetUserPasswordSuccess,
  resetUserPasswordFail,
} from "./actions"
import { CognitoUser } from "amazon-cognito-identity-js"
import userPool from "helpers/userpool"
import { toast } from "react-toastify"

// const fireBaseBackend = getFirebaseBackend()

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const cognitoUser = new CognitoUser({
      Username: user.username,
      Pool: userPool,
    })

    let response = ""
    yield new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: res => {
          response = res
          resolve()
        },
        onFailure: err => {
          reject(err)
        },
      })
    })
    console.log(response)
    if (response !== "") {
      console.log(response)
      yield put(userForgetPasswordSuccess(response))
    }
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

function* resetPassword({ payload: { user, history } }) {
  try {
    const cognitoUser = new CognitoUser({
      Username: user.username,
      Pool: userPool,
    })

    let response = ""
    yield new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(user.confirmationCode, user.newPassword, {
        onSuccess: res => {
          response = res
          toast.dismiss()
          toast.success(`${res}!, Your password has been reset.`, {autoClose: 6000})
          resolve()
        },
        onFailure: err => {
          toast.dismiss()
          toast.error(`${err}`)
          reject(err)
        },
      })
    })
    console.log(response)
    yield put(resetUserPasswordSuccess(response))
  } catch (error) {
    yield put(resetUserPasswordFail(error.message))
  }
}

function* forgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
  yield takeEvery(RESET_PASSWORD, resetPassword)
}

export default forgetPasswordSaga
