import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Authmiddleware = (props) => {
  const loginData = useSelector(state => state.login.loginData)
  if (loginData === "") {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;
