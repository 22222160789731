import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  Row,
  Input,
  Label,
  FormFeedback,
  Alert,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import logodark from "../../assets/images/logo-dark.png"
import CarouselPage from "./CarouselPage"
import { loginUser } from "store/actions"
import withRouter from "components/Common/withRouter"

import { useDispatch, useSelector } from "react-redux"

const Login2 = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [passwordShow, setPasswordShow] = useState(false)
  const [isSubmitDisable, setIsSubmitDisable] = useState(false)
  const [errMsg, setErrMsg] = useState(false)

  const {loginData, loginErr: error, loading} = useSelector(state => state.login)

  // button unclickable when loading
  useEffect(() => { loading ? setIsSubmitDisable(true) : setIsSubmitDisable(false)}, [loading])

  // handle error message
  useEffect(() => {
    error !== "" && setErrMsg(true)
    loginData !== "" && setErrMsg(false)
  }, [error, isSubmitDisable, loginData])

  //meta title
  document.title = "Login | Dragon"

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.router.navigate))
    },
  })

  function handleAccessToken() {
    console.log("test")
    if(loginData !== "") {

      for (const key of Object.keys(localStorage)) {
        //const thisKey = localStorage.key(n)
        if (key.includes("accessToken")) {
          // found it
          let search = window.location.search
          const params = new URLSearchParams(search)
          const redirectUri = params.get("redirect_uri")
          // Check if user come from app to redirect back to it or not redirect to choose app
          redirectUri === null ? navigate('/sso-applications') : window.location.href = `${redirectUri}?username=${loginData.username}`
          // window.location.href = `${redirectUri}?username=${loginData.username}`
          break
        }
      }
    }
  }

  // changing form remove err message
  useEffect(() => { setErrMsg(false) }, [validation.values.username, validation.values.password])

  // prevent login route if user login
  useEffect(() => {
    handleAccessToken()
  }, [loginData])

  return (
    <React.Fragment>
      {/* Prevent load login if login data exist  */}
      {loginData === "" ? (
        <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <CarouselPage />

              <Col xl={3}>
                <div className="auth-full-page-content p-md-4 p-3">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5">
                        <Link to="#" className="d-block auth-logo ">
                          <img
                            src={logodark}
                            alt=""
                            height="100"
                            className="logo-dark-element d-block m-auto"
                          />
                          {/* <img
                          src={logolight}
                          alt=""
                          height="18"
                          className="logo-light-element "
                        /> */}
                        </Link>
                      </div>
                      <div className="my-auto">
                        <div>
                          <h5 className="text-gold">Welcome Back !</h5>
                          <p className="text-muted">
                            Sign in to continue to Dragon.
                          </p>
                        </div>

                        <div className="mt-4">
                          <Form
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return false
                            }}
                          >
                            {errMsg ? (
                              error.includes("NotAuthorized") ? (
                                <Alert color="danger">
                                  Invalid username or password.
                                </Alert>
                              ) : (
                                <Alert color="danger">
                                  Something Error, please try again.
                                </Alert>
                              )
                            ) : null}
                            <div className="mb-3">
                              <Label className="form-label">Username</Label>
                              <Input
                                name="username"
                                className="form-control"
                                placeholder="Enter username"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ""}
                                invalid={
                                  validation.touched.username &&
                                  validation.errors.username
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.username &&
                              validation.errors.username ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.username}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <div className="float-end">
                                <Link
                                  to="/forget-password"
                                  className="text-muted"
                                >
                                  Forgot password?
                                </Link>
                              </div>
                              <Label className="form-label">Password</Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Input
                                  name="password"
                                  value={validation.values.password || ""}
                                  type={passwordShow ? "text" : "password"}
                                  placeholder="Enter Password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.password &&
                                    validation.errors.password
                                      ? true
                                      : false
                                  }
                                />
                                <button
                                  onClick={() => setPasswordShow(!passwordShow)}
                                  className="btn btn-light "
                                  type="button"
                                  id="password-addon"
                                >
                                  <i className="mdi mdi-eye-outline"></i>
                                </button>
                              </div>
                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-check">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="auth-remember-check"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="auth-remember-check"
                              >
                                Remember me
                              </label>
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-dark btn-block "
                                type="submit"
                                disabled={isSubmitDisable}
                              >
                                {isSubmitDisable && (
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 color-white"></i>
                                )}
                                Log In
                              </button>
                            </div>
                          </Form>

                          <Form action="dashboard">
                            <div className="mt-4 text-center">
                              {/* <h5 className="font-size-14 mb-3">
                              Sign in with
                            </h5> */}

                              <ul className="list-inline">
                                <li className="list-inline-item">
                                  <Link
                                    to="#"
                                    className="social-list-item bg-primary text-white border-primary me-1"
                                  >
                                    <i className="mdi mdi-facebook"></i>
                                  </Link>
                                </li>
                                <li className="list-inline-item">
                                  <Link
                                    to="#"
                                    className="social-list-item bg-pink text-white border-pink me-1"
                                  >
                                    <i className="mdi mdi-instagram"></i>
                                  </Link>
                                </li>
                                <li className="list-inline-item">
                                  <Link
                                    to="#"
                                    className="social-list-item bg-danger text-white border-danger"
                                  >
                                    <i className="mdi mdi-google"></i>
                                  </Link>
                                </li>
                                <li className="list-inline-item">
                                  <Link
                                    to="#"
                                    className="social-list-item bg-info text-white border-info"
                                  >
                                    <i className="mdi mdi-linkedin"></i>
                                  </Link>
                                </li>
                                <li className="list-inline-item">
                                  <Link
                                    to="#"
                                    className="social-list-item bg-danger text-white border-danger"
                                  >
                                    <i className="mdi mdi-youtube"></i>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </Form>
                          {/* <div className="mt-5 text-center">
                          <p>
                            Don&apos;t have an account ?
                            <Link
                              to="pages-register-2"
                              className="fw-medium text-primary"
                            >
                              Signup now
                            </Link>
                          </p>
                        </div> */}
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} Dragon Animal Care Center{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <></>
      )}
    </React.Fragment>
  )
}

export default withRouter(Login2)

Login2.propTypes = {
  history: PropTypes.object,
}
