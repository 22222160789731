import { combineReducers } from "redux";


// Authentication
import login from "./auth/login/reducer";
import forgetPassword from "./auth/forgetpwd/reducer";
import ssoApplications from "./ssoApplications/reducer";

const rootReducer = combineReducers({
  // public
  login,
  forgetPassword,
  ssoApplications
});

export default rootReducer;
