import React from "react"
import { Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
// import images
import github from "../../assets/images/brands/github.png"
import bitbucket from "../../assets/images/brands/bitbucket.png"
import dribbble from "../../assets/images/brands/dribbble.png"
import dropbox from "../../assets/images/brands/dropbox.png"
import mail_chimp from "../../assets/images/brands/mail_chimp.png"
import slack from "../../assets/images/brands/slack.png"
import ticketing from "../../assets/images/brands/ticketing.webp"
import retail from "../../assets/images/brands/retail.png"

const ApplicationsList = () => {
  return (
    <>
      <div className="px-lg-2">
        <div className="no-gutters m-0 d-flex justify-content-center">
          <div>
            <Link
              className="sso-app-icons"
              to="https://svhelp.smartqr.app/admin"
            >
              <img src={ticketing} alt="ticketing" height={30} />
              <span>Ticketing</span>
            </Link>
          </div>
          <div>
            <Link
              className="sso-app-icons"
              to="https://svretailmodule.smartqr.app/home"
            >
              <img src={retail} alt="retail" />
              <span>Retail</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default ApplicationsList
