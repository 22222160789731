import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
} from "reactstrap"

import PropTypes from "prop-types"
import { useSelector } from "react-redux"

//Import Cards

//Import Images
// import click from "../../assets/images/click.png"
import click from "../../assets/images/click-red.png"
import withRouter from "components/Common/withRouter"
import ApplicationsList from "./applicationsList"
import logodark from "../../assets/images/logo-horz.png"
import { toast } from "react-toastify"

const SSOAplication = () => {
  //meta title
  document.title = "SSO Aplications"

  const [menu, setMenu] = useState(false)
  const {
    loginData,
    loginErr: error,
    loading,
  } = useSelector(state => state.login)
  
  useEffect(() => {
    let search = window.location.search
    const params = new URLSearchParams(search)
    const permission = params.get("permission")
    if (permission === "not_allowed") {
      toast.dismiss()
      toast.error(
        "Your username currently is not authorised to access this particular application, please check with your manager."
      )
    }
  }, [])

  return (
    <React.Fragment>
      <div className="home-btn d-sm-block">
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn "
            id="page-header-user-dropdown"
            tag="button"
          >
            <span className="d-xl-inline-block ms-2 me-1">
              Welcome, <span>{loginData.username}</span>
            </span>
            <i className="mdi mdi-chevron-down d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              Logout
            </Link>
          </DropdownMenu>
        </Dropdown>
      </div>
      <section className="my-3 my-md-3 pt-5 ">
        <Container>
          <Row className="m-0">
            <Col className="col-12 text-center">
              <div className="home-wrapper">
                <div className="mb-3">
                  {/* <h2 className="fw-bold">SSO</h2> */}
                  <Link to="/dashboard" className="d-block auth-logo">
                    <img
                      src={logodark}
                      alt=""
                      height="50"
                      className="logo-dark-element m-auto"
                    />
                  </Link>
                </div>

                <Row className="justify-content-center m-0">
                  <Col sm={4}>
                    <div className="maintenance-img">
                      <img
                        src={click}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h3 className="mt-3 mt-md-5">Your applications</h3>
                <p>Please select your application</p>
                <ApplicationsList />
                {/* <Row>
                  <Col md={4}>
                    <Card className="mt-4 maintenance-box">
                      <CardBody>
                        <i className="bx bx-broadcast mb-4 h1 text-primary"></i>
                        <h5 className="font-size-15 text-uppercase">Why is the Site Down?</h5>
                        <p className="text-muted mb-0">There are many variations of passages of
                          Lorem Ipsum available, but the majority have suffered alteration.</p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card className="mt-4 maintenance-box">
                      <CardBody>
                        <i className="bx bx-time-five mb-4 h1 text-primary"></i>
                        <h5 className="font-size-15 text-uppercase">
                          What is the Downtime?</h5>
                        <p className="text-muted mb-0">Contrary to popular belief, Lorem Ipsum is not
                          simply random text. It has roots in a piece of classical.</p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card className="mt-4 maintenance-box">
                      <CardBody>
                        <i className="bx bx-envelope mb-4 h1 text-primary"></i>
                        <h5 className="font-size-15 text-uppercase">
                          Do you need Support?</h5>
                        <p className="text-muted mb-0">If you are going to use a passage of Lorem
                          Ipsum, you need to be sure there isn't anything embar.. <Link
                            to="/mailto:no-reply@domain.com"
                            className="text-decoration-underline">no-reply@domain.com</Link></p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default withRouter(SSOAplication)

SSOAplication.propTypes = {
  history: PropTypes.object,
}
