import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { loginSuccess, loginFail } from "./actions"
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js"
import userPool from "helpers/userpool"

import { getLogout } from "helpers/backend_helper"

function* loginUser({ payload: { user, history } }) {
  const authenticationDetails = new AuthenticationDetails({
    Username: user.username,
    Password: user.password,
  })

  const cognitoUser = new CognitoUser({
    Username: user.username,
    Pool: userPool,
  })

  try {
    const res = yield new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: result => {
          // const cognitoUser = userPool.getCurrentUser()
          //let search = window.location.search
          //const params = new URLSearchParams(search)
          //const redirect_uri = params.get("redirect_uri")
          // window.location.href = `${redirect_uri}?username=${cognitoUser.username}`
          resolve(result)
        },
        onFailure: err => {
          console.log("Authentication failed:", err)
          reject(err)
        },
      })
    })

    if (res) {
      yield put(loginSuccess(cognitoUser))
    }
  } catch (error) {
    yield put(loginFail(JSON.stringify(error)))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const cognitoUser = userPool.getCurrentUser()

    if (cognitoUser) {
      cognitoUser.signOut()
      // get url after logout
      let search = window.location.search
      const params = new URLSearchParams(search)
      const logout_uri = params.get("logout_uri")
      logout_uri !== null ? (window.location.href = logout_uri) : window.location.href = 'login'
    }
  } catch (error) {
    console.log(error)
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
