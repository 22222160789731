import React, {useState, useEffect} from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Button,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { userForgetPassword } from "store/actions"
// import images
import logodark from "../../assets/images/logo-dark.png"
import CarouselPage from "./CarouselPage"
import { useDispatch, useSelector } from "react-redux"

const ForgetPassword2 = () => {
  //meta title
  document.title = "Forget Password | Dragon - Animal care center"

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isSubmitDisable, setIsSubmitDisable] = useState(false)

  const {
    forgetSuccessMsg,
    loading,
  } = useSelector(state => state.forgetPassword)

  useEffect(() => {
    loading ? setIsSubmitDisable(true) : setIsSubmitDisable(false)
  }, [loading])

  useEffect(() => {
    forgetSuccessMsg !== null && navigate('/email-verification')
  }, [forgetSuccessMsg])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your email"),
    }),
    onSubmit: values => {
      console.log(values)
      dispatch(userForgetPassword(values))
    },
  })
  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="100"
                          className="auth-logo-dark d-block m-auto"
                        />
                        {/* <img
                          src={logolight}
                          alt=""
                          height="100"
                          className="auth-logo-light"
                        /> */}
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-gold">Forget Password</h5>
                        <p className="text-muted">Re-Password with Dragon.</p>
                      </div>

                      <div className="mt-4">
                        <div
                          className="alert alert-success text-center mb-4"
                          role="alert"
                        >
                          Enter your Email and instructions will be sent to you!
                        </div>

                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="username"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username &&
                                validation.errors.username
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.username &&
                            validation.errors.username ? (
                              <FormFeedback type="invalid">
                                {validation.errors.username}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mt-3 text-end">
                            <Button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              disabled={isSubmitDisable}
                            >
                              {isSubmitDisable && (
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 color-white"></i>
                              )}
                              Reset
                            </Button>
                          </div>
                        </Form>
                        <div className="mt-5 text-center">
                          <p>
                            Remember It ?{" "}
                            <Link
                              to="/login"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Sign In here{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Dragon Animal Care Center{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ForgetPassword2
