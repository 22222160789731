import PropTypes from 'prop-types';
import React from "react";
import { Routes, Route } from "react-router-dom";
import { publicRoutes, authProtectedRoutes } from "./routes";

import NonAuthLayout from "./components/NonAuthLayout";
import "./assets/scss/theme.scss";
import { useDispatch } from 'react-redux';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { loginSuccess } from 'store/actions';
import Authmiddleware from "./routes/route"

import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from "react-toastify"

import userPool from 'helpers/userpool';

const App = () => {
  const dispatch = useDispatch()
  // set cognitoUser login data while refresh
  const cognitoUser = userPool.getCurrentUser()
  cognitoUser && dispatch(loginSuccess(cognitoUser))

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                <ToastContainer theme="colored" position="top-center" />
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <ToastContainer theme="colored" position="top-center" />
                {route.component}
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;