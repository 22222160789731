import { call, put, takeEvery } from "redux-saga/effects"
import { GET_SSO_APP } from "./actionTypes"

import { getSsoAppSuccess, getSsoAppFail } from "./actions"

// Saga for getting all stock transfers
function* onGetSsoApp() {
  try {
    // const response = yield call(getStockTransfer)
    // yield put(getSsoAppSuccess(response))
  } catch (error) {
    yield put(getSsoAppFail(error.message))
  }
}

function* ssoAppSaga() {
  yield takeEvery(GET_SSO_APP, onGetSsoApp)
}

export default ssoAppSaga
